/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';


import LayoutMain from 'components/LayoutMain';
import { Header, Footer, Button, Modal } from 'components';
import { ContactForm } from 'components/forms';

import bgImg from 'assets/images/landingSection1BG3.png';
import useLayout from 'hooks/useLayout';
import { BigPictureSection, CardsSection, ContactSection, Pricing , Faqs} from 'components/sections';
import { PopupModal } from "react-calendly";

const Brokerages = () => {
  const images = useStaticQuery(graphql`
    {
        topBanner: file(relativePath: { eq: "new_simple_ops.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 447) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      section4Card1: file(relativePath: { eq: "new_brokerage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350, maxHeight: 261) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section4Card2: file(relativePath: { eq: "new_assign_team2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350, maxHeight: 261) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section4Card3: file(relativePath: { eq: "new_more_deals.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 350, maxHeight: 261) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Section10Graphics: file(relativePath: { eq: "landingSection10Graphics.png" }) {
        childImageSharp {
          fluid(maxWidth: 338, maxHeight: 416) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      moreThanSoft: file(relativePath: { eq: "new_agents_thrive.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 447) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [openCalendly, setOpenCalendly] = useState<boolean>(false);
  const [openContactForm, setOpenContactForm] = useState<boolean>(false);
  const [openRequestForm, setOpenRequestForm] = useState<boolean>(false);
  const [pageTop, setPageTop] = useState(true);
  const [canRender, setCanRender] = useState(false);
  const layout = useLayout();

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) setPageTop(true);
      else if (pageTop !== false) {
        setPageTop(false);
      }
    };
    return () => {
      window.onscroll = null;
    };
  }, [pageTop]);

  useEffect(() => {
    setPageTop(window.pageYOffset === 0);
    setCanRender(true);
  }, []);

  return (
    <LayoutMain>
        
      <Header
        border={!pageTop}
        onClickRequest={() => setOpenRequestForm(true)}
        onClickContact={() => setOpenContactForm(true)}
      />

        {canRender ? <PopupModal
          url="https://calendly.com/mybrokertools"
          onModalClose={() => setOpenCalendly(false)}
          open={openCalendly}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("rootOfModal")}
        /> : null
        }

      <StyledHomePage>
        <div id="rootOfModal"></div>
        <section className="top-section">
        <BigPictureSection
          className="back-office-picture-section"
          imagePosition="right"
          image={{ fluid: images.topBanner.childImageSharp.fluid }}>
          <h2>Simplify your operations and scale up with full transparency.</h2>
          <p>
          MybrokerTools offers reliable back-office staff at a fraction of the cost of an employee. Pay us only after you close. No closing? No fee.
          </p>
          <div className="top-section-container">
            <StyledButton onClick={() => setOpenCalendly(true)}>Get started</StyledButton>
        
          </div>
        </BigPictureSection>


          
          
          
        </section>

    

        <BigPictureSection
          className="software-section"
          imagePosition="left"
          image={{ fluid: images.moreThanSoft.childImageSharp.fluid }}>
          <h2>Make your agents thrive.</h2>
          <ul className='ulteam'>
            <li>We are available 7 days a week via phone, text and email. Our average response rate is 15 minutes.</li>
            <li>We handle all back-office work from offer to closing.</li>
            <li>Monitor real-time performance in our real estate management platform free of charge.</li>
            <li>We assist with preparing documents, communications and scheduling & coordinating inspections, surveys, closings and more.</li>
            <li>We handle the transaction. Period.</li>
          </ul>
          <StyledButton onClick={() => setOpenCalendly(true)}>Let's chat</StyledButton>
        </BigPictureSection>

        <CardsSection
          className="solutions-card-section"
          sectionTitle="Are you ready to streamline?"
          description="Contact us to speak with one of our agents to discuss what we can do for you. No sales people, just people who know and breathe real estate."
          cards={[
            {
                key: 1,
                image: images.section4Card1.childImageSharp.fluid,
                text:
                  "1. Let us know your brokerage' needs."
              },
              {
                key: 2,
                image: images.section4Card2.childImageSharp.fluid,
                text:
                  '2. We assign a team to match your exact needs.'
              },
              {
                key: 3,
                image: images.section4Card3.childImageSharp.fluid,
                text:
                  '3. We make sure you close more deals with less effort. Simple.'
              }
          ]}
        />

        {/*<Pricing
            className="clients-section"
            sectionTitle="Simple Pricing"
          />*/}


        <Faqs
          className="faqs-section"
          sectionTitle="You might have some questions."
          
          cards={[
            {
                key: 1,
                question: 'Why should I use the MyBrokerTools Team?',
                text:
                  'Our Team handles all of your pre-contract and post-contract admin work, tasks, paperwork and coordination. Any issue you run into we have you covered via our team or our trusted vendor network. By freeing up your workload you can focus on what you do best: selling.'
              },
              {
                key: 2,
                question: 'How do you streamline the transaction?',
                text:
                  'We built our own network of reliable vendors that go above and beyond. We even supplied them with their own automation tools so your transaction flows smoother than ever. Stop worrying about missing documents or incomplete data. Our integrated software network makes this a thing of the past.'
              },
              {
                key: 3,
                question: 'How can you do all of this, so cheap?',
                text:
                  'We believe in human resources powered by tech. An assistant can only do so much with excel, existing softwares, paper. Our A-Z solution enables us to cut costs, not quality.'
              },
            {
              key: 4,
              question: "So agents don't pay when a transactions doesn't close?",
              text:
                "Simple right? Yes agents do not pay when a transaction doesn't close."
            }
          ]}
        />
        

        <BigPictureSection
          className="schedule-section"
          imagePosition="left"
          image={{ fluid: images.Section10Graphics.childImageSharp.fluid }}>
          <h2>Let’s get started.</h2>
          <p>Your transformation is just a tap away.</p>
          <StyledButton onClick={() => setOpenCalendly(true)}>Request a demo </StyledButton>
        </BigPictureSection>
      </StyledHomePage>

      <Footer />
      <Modal
        modalTitle={<>Our friendly specialists would love to chat!</>}
        open={openContactForm}
        onClose={() => setOpenContactForm(false)}>
        <ContactForm extended modal onSubmit={() => setOpenContactForm(false)} />
      </Modal>
      <Modal
        modalTitle="Our specialists will be in touch!"
        open={openRequestForm}
        onClose={() => setOpenRequestForm(false)}>
        <ContactForm
          modal
          subject="Request a Demo"
          buttonText="Send Request"
          onSubmit={() => setOpenRequestForm(false)}
        />
      </Modal>
    </LayoutMain>
  );
};

const StyledHomePage = styled(LayoutMain)`
  .full-text-type-1 {
    max-width: 620px;
  }
  .full-text-type-2 {
    display: inline-block;
    max-width: 570px;
  }
  .full-text-type-3 {
    max-width: 850px;
    margin: 0 auto;
  }
  .ulteam li{margin-bottom: 14px;font-size: 16px;}
  section {
    padding: 32px 20px;
    text-align: center;
    width: 100%;
    max-width: 1246px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;

    &.top-section {
      font-size: 20px;
      line-height: 28px;
      position: relative;
      overflow: hidden;
      margin-top:0;

      p {
        margin: 32px auto;
      }

      .bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 618px;
        margin: auto;
        text-align: center;
        display: flex;
        justify-content: center;
        z-index: -1;

        .top-section-graphics-1 {
          width: 100%;
          max-width: 890px;
          height: 618px;
          top: 0;
        }
      }

      .top-section-graphics-2 {
        margin-top: 118px;
      }
      .top-section-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &.solutions-card-section {
      .cards-grid {
        display: grid;
        width: 100%;
        margin: auto;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 32px;
        justify-items: center;
        margin-top: 60px;
      }
    }
    &.schedule-section {
      .gatsby-image-wrapper {
        max-width: 350px;
        height: auto;
      }
    }
    &.dashboard-photo-section {
      padding: 0 16px;
    }

    /* &:nth-of-type(7) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 80px;
      text-align: left;

      .picture-block {
        text-align: center;
        p {
          margin: 40px 0;
        }
      }
    } */

    /* &:nth-of-type(6) {
      & > h2,
      & > p {
        text-align: left;
      }
    } */

    /* &:nth-of-type(8) {
      justify-items: center;

      .gatsby-image-wrapper {
        width: 100%;
        max-width: 338px;
        height: 416px;
      }
    } */
  }

  .clients-section img{border-radius: 50%;}

  h1 {
    margin: 32px 0;
    font-size: 64px;
    line-height: 64px;
    font-weight: normal;
  }
  h2 {
    margin: 24px 0;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
  }
  h6 {
    margin: 0 0 16px 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
  }

  .dashboard-photo {
    max-width: 920px;
    margin: 0 auto 50px;
  }

  .map {
    max-width: 1246px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 0;
    &__item {
      margin: 20px 0;
      padding: 0 20px;
      flex: 0 0 33.33333%;
      text-align: left;
    }
    &__icon {
      margin-right: 16px;
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .container-fluid {
      padding-left: 60px;
      padding-right: 60px;
    }
    .container {
      max-width: 60rem;
    }
    .top-section-graphics-3 {
      display: none;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .list {
      &__item {
        flex: 0 50%;
      }
    }
    .contact-us-wrapper {
      grid-gap: 0 64px;
    }
    h2 {
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 24px;
    }

    h6 {
      font-size: 20px;
      line-height: 28px;
    }
    p {
      font-size: 16px;
    }
    .list {
      &__item {
        margin: 12px 0;
        padding: 0;
        flex: 100%;
      }
    }
    section {
      padding: 20px 16px;
      &.top-section {
        .top-section-graphics-2 {
          display: none;
        }
        .top-section-graphics-3 {
          max-width: 332px;
          margin: 0 auto;
          margin-top: 68px;
        }

        h1 {
          font-size: 36px;
          line-height: 42px;
          margin: 0;
        }
        p {
          margin: 24px auto 32px;
        }
      }
      &.schedule-section {
        .gatsby-image-wrapper {
          max-width: 248px;
          height: auto;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    section {
      &.solutions-card-section {
        .cards-grid {
          margin-top: 0;
          grid-template-columns: 1fr;
          grid-gap: 24px;
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export default Brokerages;
